<template>
  <task-list></task-list>
</template>

<script>
import TaskList from './TaskList.vue';

export default {
  components: {
    TaskList,
  },
};
</script>
